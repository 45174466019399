(() => {
  const ERROR_CLASSNAME = 'error';
  const INVALID_CLASSNAME = 'invalid';
  const SUCCESS_CLASSNAME = 'success';

  const subscribeForm = document.querySelector('.footer__form');
  const emailInput = subscribeForm.querySelector('input[name="email"]');

  subscribeForm.addEventListener('submit', async (e) => {
    e.preventDefault();

    const email = emailInput.value;
    const emailRe = /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;

    subscribeForm.classList.remove(ERROR_CLASSNAME);
    subscribeForm.classList.remove(INVALID_CLASSNAME);
    subscribeForm.classList.remove(SUCCESS_CLASSNAME);

    if (!email) {
      subscribeForm.classList.add(ERROR_CLASSNAME);
      return;
    }

    if (!emailRe.test(email)) {
      subscribeForm.classList.add(INVALID_CLASSNAME);
      return;
    }

    await fetch(location.origin + '/subscribe', {
      method: 'POST',
      headers: {
        'content-type': 'application/json'
      },
      body: JSON.stringify({ email }),
    });

    subscribeForm.classList.add(SUCCESS_CLASSNAME);

    emailInput.value = '';

    setTimeout(() => {
      subscribeForm.classList.remove(SUCCESS_CLASSNAME);
    }, 5000);
  });

  emailInput.addEventListener('input', () => {
    subscribeForm.classList.remove(ERROR_CLASSNAME);
    subscribeForm.classList.remove(INVALID_CLASSNAME);
    subscribeForm.classList.remove(SUCCESS_CLASSNAME);
  });
})();
